<template>
  <AppLayout>
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
      <div class="widget widget-six">
        <div class="widget-heading d-block">
          <div class="row">
            <div class="col-xl-12 col-md-12 col-sm-12 col-12 d-flex align-items-center">
              <h4 class="me-3 my-0">Lista artykułów</h4>
              <div class="ms-auto" v-if="isAdmin()">
                <Link class="btn btn-outline-primary btn-sm" :href="route('articles.units.index')">Jednostki artykułów
                </Link>
                <Link class="btn btn-outline-primary btn-sm ms-2" :href="route('articles.categories.index')">Kategorie
                  artykułów
                </Link>
                <Link class="btn btn-outline-primary btn-sm ms-2" :href="route('warehouses.index')">Magazyny artykułów
                </Link>
                <Link class="btn btn-outline-primary btn-sm ms-2" :href="route('articles.deleted.index')">Usunięte
                  artykuły
                </Link>
              </div>
            </div>

            <div class="text-start">
              <template v-if="loadingRate">
                <span>Pobieram kurs €...</span>
              </template>
              <template v-else>
                <span v-if="eurRate.rate">Kurs € na dzień {{ eurRate?.date }}: <b class="text-primary">{{ eurRate.rate }}</b></span>
                <span v-else class="text-danger">Nie udało się pobrać kursu €. Odśwież stronę lub spróbuj ponownie później.</span>
              </template>
            </div>
          </div>
        </div>

        <div class="widget-content">
          <div>
            <ejs-grid ref="articlesGrid" id="articlesGrid" height="500px"
                      :dataSource="dataManager"
                      :allowTextWrap='true'
                      :allowExcelExport='false'
                      :allowPaging="true"
                      :allowSorting='true'
                      :allowFiltering='true'
                      :allowReordering='false'
                      :allowResizing='true'
                      :showColumnChooser='true'
                      :allowSelection='true'
                      :enablePersistence='false'
                      :pageSettings="pageSettings"
                      :editSettings="editSettings"
                      :filterSettings="filterOptions"
                      :toolbar="toolbar"
                      :rowDataBound="onRowDataBound"
                      :rowSelected="rowSelected"
                      :keyPressed="onKeyDown"
                      :beforeBatchAdd="beforeBatchAdd"
                      :cellEdit="onCellEdit"
                      :beforeBatchSave="beforeBatchSave"
                      :toolbarClick="toolbarClick"
                      :cellSave="cellSave">
              <e-columns>
                <e-column field="id" headerText="ID" width="92" :isPrimaryKey="true" :allowEditing="false"></e-column>
                <e-column field="catalog_number" headerText="Nr katalogowy" width="150"
                          :edit="catalogEditor"></e-column>
                <e-column field="model" headerText="Model" width="150"></e-column>
                <e-column field="article_unit.id" headerText="J.m." width="150" editType="dropdownedit"
                          :dataSource="units" foreignKeyValue='name' foreignKeyField='id'
                          :filter='filterUnits'></e-column>
                <e-column field="description" headerText="Nazwa/Opis" :defaultValue="null" width="200"></e-column>
                <e-column field="created_at" headerText="Utworzono" width="150"
                          format="dd.MM.yyyy HH:mm:ss" :allowEditing="false"></e-column>
                <e-column field="created_by.id" headerText="Utworzył" width="150" :allowEditing="false"
                          :dataSource="users" foreignKeyValue='name' foreignKeyField='id'
                          :defaultValue="$page.props.user.id" :filter="filterCreatedBy"></e-column>
                <e-column field="producer.id" headerText="Producent" width="150" editType="dropdownedit"
                          :dataSource="producers" foreignKeyValue='short_name' foreignKeyField='id'
                          :filter='filterProducers'></e-column>
                <e-column field="article_category.id" headerText="Kategoria" width="250" editType="dropdownedit"
                          :dataSource="categories" foreignKeyValue='name' foreignKeyField='id'
                          :filter='filterCategories'></e-column>
                <e-column field="price_netto_zl" headerText="Cena Netto zł" width="150" format="N"
                          type="number"></e-column>
                <e-column field="price_brutto_zl" headerText="Cena brutto zł" width="150" format="N"
                          type="number"></e-column>
                <e-column field="price_netto_eur" headerText="Cena Netto eur" width="150" format="N"
                          type="number"></e-column>
                <e-column field="price_brutto_eur" headerText="Cena Brutto eur" width="150" format="N"
                          type="number"></e-column>
                <e-column field="photos" headerText="Zdjęcia" width="200" :template="'imageTemplate'"
                          :allowEditing="false" :allowFiltering="false"></e-column>
                <e-column field="warehouse.id" headerText="Lokalizacja (magazyn)" width="150" editType="dropdownedit"
                          :dataSource="warehouses" foreignKeyValue='short_name' foreignKeyField='id'
                          :filter='filterWarehouses'></e-column>
                <e-column field="stock_quantity" headerText="Ilość/Stany magazynowe" width="150" type="number"
                          format="N" editType="numericedit"></e-column>
                <e-column field="income" headerText="Przychód" width="150" format="N" type="number"
                          editType="numericedit"></e-column>
                <e-column field="outflow" headerText="Rozchód" width="150" format="N" type="number"
                          editType="numericedit"></e-column>
                <e-column field="value_net" headerText="Wartość (netto)" width="150" format="N" type="number"
                          editType="numericedit"></e-column>
                <e-column field="value_gross" headerText="Wartość (brutto)" width="150" format="N" type="number"
                          editType="numericedit"></e-column>
                <e-column field="suppliers_short_names" headerText="Dostawca" width="150" editType="string"
                          :filter='filterSuppliers' :edit="suppliersParams"></e-column>
              </e-columns>

              <template v-slot:imageTemplate="{ data }">
                <div v-if="data.photos && data.photos.length > 0">
                  <button type="button" class="btn-invisible" data-bs-toggle="modal"
                          data-bs-target="#gallery"
                          @click="setGalleryToModal(data)">
                    <img :src="`/${data.photos[0].photo_path}`" alt="Zdjęcie" width="150"/>
                    <span v-if="data.photos.length > 1" class="d-block mt-1 bold">
                                    +{{ data.photos.length - 1 }} więcej
                                </span>
                  </button>
                </div>
                <div v-else class="text-center">
                  <button type="button" class="btn btn-xs btn-outline-default" data-bs-toggle="modal"
                          data-bs-target="#gallery"
                          @click="setGalleryToModal(data)">
                    Dodaj zdjęcie
                  </button>
                </div>
              </template>
            </ejs-grid>
          </div>

          <ViewArticleDetails :selectedArticle="selectedArticle"></ViewArticleDetails>

        </div>
      </div>
    </div>


    <!-- Modal z galerią zdjęć -->

    <div class="modal fade" id="gallery" tabindex="-1" role="dialog" aria-labelledby="modelTitleId"
         aria-hidden="true">
      <div class="modal-dialog modal-xxl" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Galeria zdjęć</h5>
            <button type="button" class="close ms-auto" data-bs-dismiss="modal" aria-label="Zamknij">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" v-if="selectedArticle">
            <div v-if="selectedArticle.photos && selectedArticle.photos.length > 0">
              <div class="row">
                <!-- Wyświetlanie zdjęć -->
                <div v-for="(photo, index) in selectedArticle.photos" :key="index"
                     class="col image-column text-center">
                  <a :href="`/${photo.photo_path}`" target="_blank">
                    <img class="img-fluid" :src="`/${photo.photo_path}`" :alt="`Zdjęcie ${index + 1}`"
                         style="max-width: 45vw; max-height: 85vh;"/>
                  </a>

                  <!-- Ustawienie zdjęcia jako głównego -->
                  <div v-if="selectedArticle.photos.length > 1 && index > 0"
                       class="square-radio text-color radio-primary custom-control custom-radio mt-4 text-center">
                    <input type="radio" class="custom-control-input"
                           :id="`photo_${index}`"
                           name="default"
                           :value="photo.id"
                           v-model="photo.default"
                           @click="confirmSetDefault(photo.id)"/>
                    <label class="custom-control-label" :for="`photo_${index}`"> Ustaw jako zdjęcie główne </label>
                  </div>

                  <!-- Usuwanie zdjęcia -->
                  <div v-if="photo.id">
                    <button class="btn btn-danger mt-2" @click="confirmDeletePhoto(photo.id)">Usuń zdjęcie</button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Formularz dodawania zdjęcia -->
            <div class="mt-4">
              <hr>
              <form @submit.prevent="uploadPhoto(selectedArticle.id)">
                <div class="form-group">
                  <label for="photoUpload">Dodaj nowe zdjęcie</label>
                  <input type="file" class="form-control" id="photoUpload" @change="handleFileUpload">
                </div>
                <button type="submit" class="btn btn-primary mt-2">Zapisz zdjęcie</button>
              </form>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Zamknij</button>
          </div>
        </div>
      </div>
    </div>


    <!-- Modal z historią artykułu -->
    <ArticleHistoryGrid v-if="showHistoryModal" @close="showHistoryModal = false"
                        :selectedArticle="selectedArticle"></ArticleHistoryGrid>

  </AppLayout>
</template>

<script>
import AppLayout from "../../../Layouts/App.vue";
import {useForm, usePage} from "@inertiajs/vue3";
import {AutoComplete, MultiSelect} from '@syncfusion/ej2-vue-dropdowns';
import {TextBox} from '@syncfusion/ej2-inputs';
import {
  ColumnChooser,
  ColumnDirective,
  ColumnsDirective,
  Edit,
  ExcelExport,
  Filter, ForeignKey,
  GridComponent,
  Page,
  Reorder,
  Resize,
  Sort,
  Toolbar,
  VirtualScroll
} from '@syncfusion/ej2-vue-grids';
import {usePermission} from "@/composables/resources/js/composables/permissions.js";
import {createElement} from "@syncfusion/ej2-base";
import {DataManager, WebMethodAdaptor, WebApiAdaptor, ODataAdaptor} from '@syncfusion/ej2-data';
import {DropDownList} from "@syncfusion/ej2-dropdowns";
import moment from "moment";
import ArticleHistoryGrid from '@/Pages/Warehouses/Articles/ArticleHistoryGrid.vue';
import ViewArticleDetails from "./ViewArticleDetails.vue";

const {hasRole} = usePermission();

let dropInstanceFilterUnits;
export default {
  computed: {
    id() {
      return id
    },

    catalogNumbers() {
      return this.articles.map(article => article.catalog_number).filter((value, index, self) => self.indexOf(value) === index);
    }
  },

  components: {
    ViewArticleDetails,
    AppLayout,
    hasRole,
    MultiSelect,
    ArticleHistoryGrid,
    'ejs-grid': GridComponent,
    'e-columns': ColumnsDirective,
    'e-column': ColumnDirective
  },

  provide: {
    grid: [Page, Edit, Toolbar, Sort, Filter, Reorder, Resize, ColumnChooser, ExcelExport, ForeignKey, VirtualScroll]
  },

  props: {
    units: Object,
    categories: Object,
    warehouses: Object,
    producers: Object,
    suppliers: Object,
    users: Object
  },

  mounted() {
    this.getNBPExchangeRate();
    // this.fetchArticles(1, this.pageSettings.pageSize);
  },

  data() {
    let ddElem;
    let multiSelectObj;

    const createSuppliersFn = () => {
      ddElem = document.createElement('input');
      return ddElem;
    };

    const readSuppliersFn = () => {
      return multiSelectObj.value.join(","); // Zwróć wartości jako string
    };

    const destroySuppliersFn = () => {
      multiSelectObj.destroy(); // Usunięcie instancji MultiSelect
    };

    const writeSuppliersFn = (args) => {
      const selectedValues = args.rowData.suppliers_short_names ? args.rowData.suppliers_short_names.split(",") : [];

      // Inicjalizacja MultiSelect
      multiSelectObj = new MultiSelect({
        value: selectedValues,
        dataSource: this.suppliers,
        fields: {text: "short_name", value: "short_name"},
        placeholder: 'Wybierz dostawców',
        mode: 'Box',
        allowFiltering: true,
        popupHeight: '200px',
        closePopupOnSelect: false,
      });

      multiSelectObj.appendTo(ddElem);
    };

    return {
      photoForm: useForm({
        article_id: null,
        default: null,
        photo_path: null,
      }),
      articles: [],
      loadingRate: false,
      eurRate: {rate: 0, date: null},
      dataManager: new DataManager({
        url: route('articles.get'),
        adaptor: new WebMethodAdaptor(),
        batchUrl: route('articles.saveChanges'),
        crossDomain: true,
        headers: [
          {'X-CSRF-TOKEN': usePage().props.csrf_token}
        ]
      }),
      pageSettings: {pageSize: 50, pageCount: 5},
      showHistoryModal: false,
      selectedFile: null,
      isAddingNew: false,
      selectedArticle: null,
      taskGridKey: 0,
      filterOptions: {type: "Menu"},
      toolbar: this.getToolbar(),
      editSettings: this.getEditSettings(),
      selectionOptions: {type: 'Multiple', cellSelectionMode: 'Box', mode: 'Cell'},
      suppliersParams: {
        create: createSuppliersFn,
        destroy: destroySuppliersFn,
        read: readSuppliersFn,
        write: writeSuppliersFn
      },
      filterUnits: {
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.units,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj jednostki',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },
      filterCreatedBy: {
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.users,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj kto stworzył',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },

      filterCategories: {
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.categories,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj kategorii',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },
      filterWarehouses: {
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.warehouses,
              fields: {text: 'name', value: 'id'},
              placeholder: 'Szukaj magazynu',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },
      filterProducers: {
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.producers,
              fields: {text: 'short_name', value: 'id'},
              placeholder: 'Szukaj Producenta',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },
      filterSuppliers: {
        ui: {
          create: (args) => {
            let flValInput = createElement('input', {className: 'flm-input'});
            args.target.appendChild(flValInput);
            dropInstanceFilterUnits = new DropDownList({
              dataSource: this.suppliers,
              fields: {text: 'short_name', value: 'short_name'},
              placeholder: 'Szukaj dostawców',
              popupHeight: '200px',
              allowFiltering: true
            });
            dropInstanceFilterUnits.appendTo(flValInput);
          },
          write: (args) => {
            dropInstanceFilterUnits.text = args.filteredValue || '';
          },
          read: (args) => {
            args.fltrObj.filterByColumn(args.column.field, args.operator, dropInstanceFilterUnits.text);
          }
        }
      },
    }
  },

  methods: {
    // async fetchArticles(page, pageSize) {
    //   const sort = this.sortSettings;
    //   const filters = this.filterSettings;
    //   try {
    //     const response = await axios.get(route('articles.get'), {
    //       params: {
    //         page: page,
    //         pageSize: pageSize,
    //         sortField: sort.field || null,
    //         sortOrder: sort.direction || null,
    //         filters: filters || null
    //       }
    //     });
    //     this.articles = response.data.articles; // Oczekujemy, że serwer zwróci paginowane dane
    //     this.$refs.articlesGrid.ej2Instances.pageSettings.totalRecordsCount = response.data.totalRecords;
    //   } catch (error) {
    //     console.error('Błąd podczas pobierania danych:', error);
    //   }
    // },

    actionBegin(args) {
      // Ta metoda zostanie wywołana, gdy użytkownik zmieni stronę
      // if (args.requestType === 'paging') {
      //   this.currentPage = args.currentPage;
      //   this.fetchArticles(this.currentPage);  // Pobierz dane dla nowej strony
      // } else if (args.requestType === 'sorting') {
      //   this.sortSettings = { field: args.columnName, direction: args.direction };
      //   this.fetchArticles(this.currentPage);  // Zastosuj sortowanie
      // } else if (args.requestType === 'filtering') {
      //   this.filterSettings = args.currentFilterObject;  // Aktualizuj filtry
      //   this.fetchArticles(this.currentPage);  // Zastosuj filtrowanie
      // }
    },

    async getNBPExchangeRate() {
      this.loadingRate = true;
      try {
        const response = await axios.get(route('api.euro-rate'));
        if (response.data.rate && response.data.date) {
          this.eurRate = {
            rate: response.data.rate, // Pobieranie kursu EUR
            date: response.data.date  // Data kursu
          }
        } else {
          console.error('Nie udało się pobrać kursu EUR', response);
          this.eurRate = {rate: 0, date: null};
        }
      } catch (error) {
        console.error('Błąd podczas pobierania kursu EUR:', error);
        this.eurRate = {rate: 0, date: null};
      }
      this.$nextTick(() => {
        this.loadingRate = false;
      })
    },

    getEditSettings() {
      return {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        showConfirmDialog: false,
        showDeleteConfirmDialog: false,
        mode: 'Batch',
        newRowPosition: 'Top',
      };
    },

    getToolbar() {
      return [
        "Add",
        {text: 'Usuń', prefixIcon: 'e-delete', id: 'delete_btn'},
        "Update",
        "Cancel",
        {text: 'Historia wpisu', prefixIcon: 'e-clock', id: 'show_history_btn', disabled: false},
        {text: "Search", prefixIcon: 'e-search', id: "search", align: 'center'},
        {text: "Export do Excela", prefixIcon: 'e-excelexport', id: "excelexport", align: 'right'},
        "ColumnChooser",
      ];
    },

    userCanModify() {
      let rights = 0;
      if (this.usersCanChange) {
        rights = this.usersCanChange.find(email => email === this.$page.props.user.email);
      }

      return !(rights === undefined || rights <= 0);
    },

    beforeBatchAdd(args) {
      // blokada na tworzenie jednocześnie kilku wpisów
      if (this.isAddingNew) {
        args.cancel = true;
      } else {
        this.isAddingNew = true;
      }
    },

    onCellEdit(args) {
      if (args.rowData.deleted_at) {
        // Anuluj edycję, jeśli rekord został usunięty
        args.cancel = true;
        // Opcjonalnie wyświetl powiadomienie
        console.log('Nie można edytować usuniętych rekordów.');
      }
    },

    toolbarClick(args) {
      // cancellowanie isAddingNew, tzn. jak się kliknie update lub cancel to powinno dać się znowu dodać nowy wiersz
      if (args.item.id === 'articlesGrid_update' || args.item.id === 'articlesGrid_cancel') {
        this.isAddingNew = false;
      }

      // Obsługa przycisku delete
      if (args.item.id === 'delete_btn') {
        const selectedRecords = this.$refs.articlesGrid.ej2Instances.getSelectedRecords();

        if (selectedRecords.length > 0) {
          // Usuwanie zaznaczonego rekordu
          this.$refs.articlesGrid.ej2Instances.deleteRecord('id', selectedRecords[0]);
        }
      }

      // Obsługa przycisku pokazywania historii konkretnego wiersza
      if (args.item.id === 'show_history_btn') {
        if (this.selectedArticle) {
          this.showHistoryModal = true;
        }
      }
    },

    async beforeBatchSave(args) {
      this.$makeClean();
      try {
        const mapFields = (record) => ({
          id: record.id,
          catalog_number: record.catalog_number,
          model: record.model,
          unit_id: record.article_unit?.id,
          description: record.description,
          created_by: record.created_by?.id,
          category_id: record.article_category?.id,
          price_netto_zl: record.price_netto_zl,
          price_brutto_zl: record.price_brutto_zl,
          price_netto_eur: record.price_netto_eur,
          price_brutto_eur: record.price_brutto_eur,
          photos: record.photos,
          warehouse_id: record.warehouse?.id,
          producer_id: record.producer?.id,
          stock_quantity: record.stock_quantity,
          income: record.income,
          outflow: record.outflow,
          value_net: record.value_net,
          value_gross: record.value_gross,
          suppliers: record.suppliers_short_names,
        });

        // Pobierz zmodyfikowane dane (nowe, zmienione, usunięte)
        const updatedRecords = args.batchChanges.changedRecords.map(mapFields);
        const addedRecords = args.batchChanges.addedRecords.map(mapFields);
        const deletedRecords = args.batchChanges.deletedRecords.map(record => ({id: record.id}));

        // Wysyłanie danych przy użyciu Inertia.js z `onSuccess`
        this.$inertia.post(route('articles.saveChanges'),
            {
              updated: updatedRecords,
              added: addedRecords,
              deleted: deletedRecords,
            },
            {
              only: ['errors', 'flash', 'newRecords', 'updatedRecords', 'deletedRecords', 'articles'],
              preserveState: true,
              onSuccess: (page) => {
                const newRecords = page.props.newRecords || [];
                const updatedRecordsFromServer = page.props.updatedRecords || [];

                // Zaktualizuj Syncfusion Grid:
                newRecords.forEach((newRecord) => {
                  const index = this.$refs.articlesGrid.ej2Instances.getIndexByRowData({id: newRecord.temp_id});
                  if (index !== -1) {
                    this.$refs.articlesGrid.ej2Instances.updateRow(index, newRecord);
                  } else {
                    this.$refs.articlesGrid.ej2Instances.addRecord(newRecord);
                  }
                });

                updatedRecordsFromServer.forEach((updatedRecord) => {
                  const index = this.$refs.articlesGrid.ej2Instances.getIndexByRowData({id: updatedRecord.id});
                  if (index !== -1) {
                    this.$refs.articlesGrid.ej2Instances.updateRow(index, updatedRecord);
                  }
                });

                this.successMsg('Dane zostały zapisane!');
                this.$makeClean();
                this.$refs.articlesGrid.ej2Instances.refresh();
              },

              onError: (errors) => {
                console.error('Błąd podczas zapisywania danych:', errors);
                this.errorMsg('Błąd podczas zapisywania danych.');
              },
            }
        );
      } catch (error) {
        console.log(error);
        this.$makeDirty();
        console.error('Błąd podczas zapisywania danych:', error);
        this.errorMsg('Błąd podczas zapisywania danych.');
      }
    },


    async cellSave(args) {
      if (args.previousValue !== args.value) {
        this.$makeDirty();
      }

      /**
       * Przy wyjściu z edycji Nr katalogowego i zmianie jego wartości sprawdź, czy jest już taki w bazie,
       * jeśli tak, to wyświetl komunikat.
       */
      if (args.columnName === 'catalog_number' && args.previousValue !== args.value) {
        let sameArticles = await this.checkCatalogNumber(args.value);
        if (sameArticles.length > 0) {
          this.articleAlreadyExist(sameArticles)
        }
      }

      /**
       * podczas edycji price_netto_zl uzupełnij rownież price_netto_eur w oparciu o NBP
       */
      if (args.columnName === 'price_netto_zl') {
        const priceNettoZl = parseFloat(args.value);

        // Jeśli kurs EUR został pobrany i cena netto w zł jest prawidłowa
        if (this.eurRate.rate && !isNaN(priceNettoZl)) {
          const priceNettoEur = priceNettoZl / this.eurRate.rate;

          // Znajdź właściwy wiersz w bieżących danych widoku
          const currentRecords = this.$refs.articlesGrid.ej2Instances.getCurrentViewRecords();
          const rowIndex = currentRecords.findIndex(record => record.id === args.rowData.id);

          const roundedPriceNettoEur = priceNettoEur.toFixed(2);
          this.$refs.articlesGrid.ej2Instances.updateCell(rowIndex, 'price_netto_eur', roundedPriceNettoEur);
        }
      }

      /**
       * podczas edycji price_netto_eur uzupełnij rownież price_netto_zl w oparciu o NBP
       */
      if (args.columnName === 'price_netto_eur') {
        const priceNettoEur = parseFloat(args.value);

        // Jeśli kurs EUR został pobrany i cena netto w EUR jest prawidłowa
        if (this.eurRate.rate && !isNaN(priceNettoEur)) {
          const priceNettoZl = priceNettoEur * this.eurRate.rate;

          // Znajdź właściwy wiersz w bieżących danych widoku
          const currentRecords = this.$refs.articlesGrid.ej2Instances.getCurrentViewRecords();
          const rowIndex = currentRecords.findIndex(record => record.id === args.rowData.id);

          const roundedPriceNettoZl = priceNettoZl.toFixed(2);
          this.$refs.articlesGrid.ej2Instances.updateCell(rowIndex, 'price_netto_zl', roundedPriceNettoZl);
        }
      }
    },

    async checkCatalogNumber(catalogNumber) {
      if (catalogNumber) {
        try {
          const response = await axios.post(route('article.check-catalog-number-if-exists'), {
            catalog_number: catalogNumber
          });
          return response.data;
        } catch (error) {
          console.error('Błąd podczas sprawdzania numeru katalogowego:', error);
          return [];
        }
      }
      return [];
    },

    async articleAlreadyExist(theSameArticles) {
      let html = '<table class="table table-bordered"><thead><tr><th>Nr Kat.</th><th>Model</th><th>Opis</th></tr></thead><tbody>';
      theSameArticles.forEach(article => {
        html += '<tr>';
        html += `<td>${article.catalog_number}`;
        html += `<td>${article.model || ''}</td>`;
        html += `<td>${article.description || ''}</td>`;
        html += '</tr>';
      });

      html += '</tbody></table>';

      const {value: selectedOption} = await Toast.fire({
        position: 'center',
        toast: false,
        icon: 'warning',
        title: 'Istnieje już taki nr katalogowy',
        html: html,
        timer: false,
        showCancelButton: false,
        showCloseButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Ok',
      });
    },

    onRowDataBound(args) {
      if (args.data.deleted_at) {  // Sprawdź, czy rekord został "miękko" usunięty
        args.row.classList.add('deleted-row');
      }
    },

    rowSelected(args) {
      this.selectedArticle = args.data;

      if (this.selectedArticle)
        this.$refs.articlesGrid.ej2Instances.toolbarModule.enableItems(document.querySelector('#show_history_btn'), true);
    },

    dateFormatter(field, data) {
      const date = data.created_at ? data.created_at : null;
      if (date) {
        try {
          return moment(date).format('DD.MM.YYYY HH:mm:ss');
        } catch (error) {
          return data
        }
      }

      return '';
    },

    onKeyDown: function (args) {
      let keyCode = args.which || args.keyCode;
      let isCtrlKey = (args.ctrlKey || args.metaKey) ? true : ((keyCode === 17));

      // code 83 to 'S'
      if (isCtrlKey && keyCode === 83) {
        args.preventDefault();

        if (this.userCanModify) {
          // zapisz
          let toolbar = document.getElementsByClassName('e-toolbar-left')[0];
          let buttons = toolbar.querySelectorAll('.e-toolbar-item');
          let updateButton = buttons[2].querySelector('button');
          if (updateButton) {
            updateButton.click();
          }
        }
      }
    },

    successMsg(resp) {
      Toast.fire({
        position: 'top-end',
        toast: true,
        icon: 'success',
        title: 'Sukces!',
        html: resp,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
      });
    },

    errorMsg(resp) {
      Toast.fire({
        position: 'top-end',
        toast: true,
        icon: 'warning',
        title: 'Błąd!',
        html: resp,
        showClass: {popup: 'animate__animated animate__fadeInDown'},
        hideClass: {popup: 'animate__animated animate__fadeOutUp'},
        timer: 2500,
        timerProgressBar: true,
        showConfirmButton: false,
        showCloseButton: true
      });
    },

    setGalleryToModal(data) {
      this.photoForm.article_id = data.id;
    },

    uploadPhoto(articleId) {
      // Dodawanie nowego zdjęcia
      if (this.selectedFile) {
        this.photoForm.article_id = articleId;
        this.photoForm.photo_path = this.selectedFile;

        // Wysyłanie zdjęcia do serwera
        this.photoForm.post(route('article.photos.store', {article: articleId}), {
          only: ['articles', 'flash'],
          onSuccess: (response) => {
            const updatedArt = this.articles.find(article => article.id === articleId);
            if (updatedArt)
              this.selectedArticle.photos = updatedArt.photos;
          }
        });
      }
    },

    handleFileUpload(event) {
      this.selectedFile = event.target.files[0];
    },

    setDefaultPhoto(photoId) {
      // Wysyła zapytanie do serwera, np. za pomocą Inertia.js lub axios
      this.photoForm.post(route('article.photos.setDefault', {
        only: ['articles', 'flash'],
        article: this.photoForm.article_id,
        photo: photoId
      }), {
        onSuccess: (resp) => {
          const updatedArt = this.articles.find(article => article.id === this.photoForm.article_id);
          this.selectedArticle = updatedArt;
          this.photoForm.default = true;
          this.successMsg(resp.props.flash.success);
        },
        onError: (error) => {
          console.error('Błąd podczas ustawiania zdjęcia głównego:', error);
          this.errorMsg(resp.props.flash.error)
        }
      });
    },

    async confirmSetDefault(photoId) {
      await Toast.fire({
        title: 'Czy na pewno chcesz ustawić to zdjęcie jako główne?',
        icon: 'question',
        position: 'center',
        toast: false,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Tak, ustaw jako główne!',
        cancelButtonText: 'Anuluj',
        timer: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.setDefaultPhoto(photoId);
        } else {
          // Przy anulowaniu, resetuj zaznaczenie
          this.photoForm.default = null;
        }
      });
    },

    async confirmDeletePhoto(photoId) {
      await Toast.fire({
        title: 'Czy na pewno chcesz usunąć to zdjęcie?',
        icon: 'warning',
        html: 'Ta akcja jest nie odwracalna!',
        position: 'center',
        toast: false,
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: 'Tak, usuń całkowicie!',
        cancelButtonText: 'Anuluj',
        timer: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deletePhoto(photoId);
        }
      });
    },

    deletePhoto(photoId) {
      // Usuwanie zdjęcia
      this.photoForm.delete(route('article.photos.destroy', {photo: photoId}), {
        only: ['articles', 'flash'],
        onSuccess: () => {
          // usuń z listy zdjęcie
          const photoIndex = this.selectedArticle.photos.findIndex(photo => photo.id === photoId);
          if (photoIndex !== -1) {
            this.selectedArticle.photos.splice(photoIndex, 1);
          }
        }
      });
    },

    isAdmin() {
      return hasRole('ADMIN');
    },

    catalogEditor() {
      return {
        create: () => {
          const elem = document.createElement('input');
          return elem;
        },
        read: () => {
          return this.autoCompleteObj.value;
        },
        destroy: () => {
          if (this.autoCompleteObj) {
            this.autoCompleteObj.destroy();
          }
        },
        write: (args) => {
          this.autoCompleteObj = new AutoComplete({
            dataSource: this.catalogNumbers,
            placeholder: 'Wpisz lub wybierz istniejący numer katalogowy',
            allowCustom: true, // Pozwala użytkownikowi wpisać nową wartość
            value: args.rowData ? args.rowData.catalog_number : '' // Ustawienie aktualnej wartości
          });
          this.autoCompleteObj.appendTo(args.element);
        }
      };
    }
  }
}
</script>

<style scoped>
.image-column {
  padding: 15px;
  border: 1px solid #ddd;
  align-items: center;
}

.btn-invisible {
  background-color: transparent;
  padding: 5px;
}
</style>